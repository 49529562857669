<template>
  <v-card>
    <MarksLegalsPgHeader></MarksLegalsPgHeader>
    <section class="form-full-width">
      <FormMarksLegalsPg :loading="loading" :getMarksLegalsPg="getMarksLegalsPg" @beforeUpdate="updateMarksLegalsPg"></FormMarksLegalsPg>
    </section>
  </v-card>
</template>

<script>
import FormMarksLegalsPg from '@/components/forms/FormMarksLegalsPg.vue';
import MarksLegalsPgHeader from './MarksLegalsPgHeader.vue';
import useMarksLegalsPg from '@/composables/useMarksLegalsPg';

export default {
  components: {
    FormMarksLegalsPg,
    MarksLegalsPgHeader,
  },
  setup() {
    // Composition API
    const {
      marksLegalsPg,
      loading,
      // methods
      getMarksLegalsPg,
      updateMarksLegalsPg,
    } = useMarksLegalsPg()

    return {
      marksLegalsPg,
      loading,
      // methods
      getMarksLegalsPg,
      updateMarksLegalsPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
