import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useMarksLegalsPg = () => {
  // base data
  const marksLegalsPg = ref (null);
  const loading = ref (false);

  // methods
  const getMarksLegalsPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchMarksLegalsPg');
    loading.value = false;
    if (status != 200) return;
    marksLegalsPg.value = data;
    return data;
  };

  const updateMarksLegalsPg = async marksLegalsPg => {
    loading.value = true;
    const {data, status} = await store.dispatch ('updateMarksLegalsPg', marksLegalsPg);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    marksLegalsPg,
    loading,
    // methods
    getMarksLegalsPg,
    updateMarksLegalsPg,
  };
};

export default useMarksLegalsPg;
